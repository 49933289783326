.model-container {
    z-index: 991;
    width: 100%;
    height: 100vh;
}


.modal-slider {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: auto;
}
.prev-btn,
.next-btn{
    color: #fff;
    font-size: 40px;
    position: absolute;
    z-index: 992;
    cursor: pointer;
    transition: .3s ease;
}
.prev-btn:hover,
.next-btn:hover{
    color: #9c9c9c;
}
.prev-btn{
    left: 1%;
    top: 50%;
}

.next-btn{
    right: 1%;
    top: 50%;
}