.intro-scene {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.intro-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.ascii-wrapper {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: "hidden",
}

svg {
  width: 200%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

svg text {
  font-family: Biko, sans-serif;
  font-weight: 700;
  font-size: 38px;
}

svg rect {
  fill: orange;
}

svg>rect {
  -webkit-mask: url(#mask);
  mask: url(#mask);
}

#scrolling-text {
  animation: scrolling 120s linear infinite;
  animation-timing-function: ease-out;
  white-space: nowrap;
  /* Eklediğimiz yeni stil */
}

@keyframes scrolling {
  0% {
    transform: translateX(30%);
  }

  100% {
    transform: translateX(-200%);
  }

}