@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Press+Start+2P&family=Victor+Mono:wght@100&display=swap');*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}
body{
  background-color: #000;
  font-family: 'Victor Mono', monospace;
  line-height: 25px;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f9f9f9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000;
}