

.grid {
	display: grid;
	margin: 10vh 0;
	grid-template-columns: repeat(5,1fr);
	border: 1px solid #1f1f1f;
	background: #000000;
	gap: 1px;
	width: 80%;
	margin: 200px auto ;
}

.grid__item {
	padding: 1.5rem 1.5rem;
	align-content: start;
	background-color: black;
}

.grid__item-img {
	width: 100%;
	aspect-ratio: 1;
	position: relative;
	overflow: hidden;
	display: grid;
	place-items: center;
	--x: 0px; 
	--y: 0px;
}

.grid__item-img::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: radial-gradient(rgb(255, 255, 255) 40%, rgb(255, 255, 255) 50%, rgb(255, 255, 255), rgb(255, 255, 255));
	mix-blend-mode: darken;
}

.grid__item-img-deco {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	font-size: 0.85rem;
	word-wrap :break-word;
	line-height: 1.15;
	color: #fff;
	opacity: 0;
	-webkit-mask-image: radial-gradient(300px circle at var(--x) var(--y),black 20%,rgba(0,0,0,0.25),transparent);
	mask-image: radial-gradient(300px circle at var(--x) var(--y),black 20%,rgba(0,0,0,0.25),transparent);
}
.grid__item-tag {
	align-self: end;
	justify-self: start;
	border: 1px solid #000000;
	border-radius: 1em;
	line-height: 0.75;
	padding: 0.5rem 0.75rem;
	font-size: 0.85rem;
}


.grid-image{
	width: 40%;
	max-width: 180px;
	position: relative;
	z-index: 1;
	filter: grayscale(1);
}


.grid__item-img:hover .grid-image{
	transition: .3s ease-in-out;
filter: grayscale(0);
}

@media screen and (max-width: 900px) {
	.grid {
		grid-template-columns: repeat(2,1fr);
	}
}

@media screen and (min-width: 53em) {
	.frame {
		grid-template-columns: auto 1fr 1fr;
		grid-template-rows: auto auto auto;
		align-content: space-between;
		justify-items: start;
		grid-row-gap: 1.5rem;
	}
	.grid {
		grid-template-columns: repeat(5,1fr);
	}
	body #cdawrap {
	    justify-self: end;
	}
}