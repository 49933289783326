/* cursor.css */
.custom-cursor {
    position: fixed;
    z-index: 9999;
    border-radius: 50%;

    transition: transform 0.1s ease;
    color: #fff;
    font-size: 50px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
