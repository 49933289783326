.projects-container {
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 100px 0;
}

.projects-wrapper {
    width: 90%;
    height: 90vh;
    margin: auto;
    display: grid;
    grid-template-rows: 100vh 1fr auto;
    grid-template-columns: 100%;
    position: relative;
    overflow: hidden;
}

.projects-wrapper-active {
    display: grid;
    grid-template-rows: 60% 30%;
    gap: 20px;
    width: 90%;
    margin: auto;
    height: 70vh;
}

.canvas {
    overflow: clip;
    margin: auto;
    position: absolute;
    width: 100%;
    height: 90vh;
    opacity: 0;
    /*100%*/
    transition: clip-path 2s ease, width .1s ease 2s, height .1s ease 2s;
    cursor: none;
    background-size: 100%;
    background-repeat: no-repeat;
}

.active-canvas {
    position: relative;
    z-index: 1;
    width: 50%;
    height: 40vh;
    transition: clip-path 2s ease, width .1s ease 2s, height .1s ease 2s;
    cursor: none;
    background-size: 100%;
    background-repeat: no-repeat;
}

.canvas canvas {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
}

.canvas-active canvas {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
}

/*Reavel*/
.custom-reveal-class{
    height: 100%;
}

/*-------*/




.projects-wrapper-top {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 45%);
    gap: 20px;
    width: auto;
    height: auto;
}

.active-canvas {
    width: 100%;
    height: 40vh;
    object-fit: cover;
    object-position: center;
}

.project-details {
    display: none;
    transition: 2s ease;
}

.project-details-active {
    width: 100%;
    height: auto;
    display: block;
    transition: 2s ease;
}

.project-slider {
    width: 90%;
    overflow: hidden;
}

@media only screen and (max-width: 1200px){
    .canvas {
        height: 50vh;
    }
    .custom-reveal-class{
        height: 50vh;
    }
    .projects-wrapper {
        height: 50vh;
    }
    
}



@media only screen and (max-width: 900px){
    .projects-container {
        margin: 100px 0;
    }
    .projects-wrapper-active {
        grid-template-rows: 60% 30%;
        width: 100%;
        height: 100vh;
    }
    .projects-wrapper-top {
        grid-template-columns: 100%;
        grid-template-rows: repeat(2, 1fr);
        gap: 10px;
    }
    .canvas-active canvas {
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
    }
    .project-details-active {
        width: 100%;
        height: auto;
        display: block;
        transition: 2s ease;
        padding: 0 20px;
    }
    .project-slider {
        width: 100%;
        overflow: hidden;
    }
    .canvas {
        height: 30vh;
    }
    .custom-reveal-class{
        height: 40vh;
    }
    .projects-wrapper {
        height: 40vh;
    }
    
}

@media only screen and (max-width: 600px){
    .canvas {
        height: 30vh;
       
    }
    .custom-reveal-class{
        height: 30vh;
    }
    .projects-wrapper {
        height: 30vh;
    } 
    .projects-container {
        margin: 10px 0;
    }
    .projects-wrapper-active {
        grid-template-rows:repeat(2,1fr);
        width: 100%;
        height: 100vh;
    }
    .projects-wrapper-top {
        grid-template-columns: 100%;
        grid-template-rows: auto 400px;
        gap: 10px;
    }
    
}

@media only screen and (max-width: 375px){
 
    .canvas {
        height: 20vh;
        background-size:  100%;
    }
    .custom-reveal-class{
        height: 20vh;
    }
    .projects-wrapper {
        height: 20vh;
    }
    
}


.custom-canvas{
    pointer-events: none;
}




