.contact-container{
    width: 100%;
    height: auto;
    color: #fff;
    text-decoration: none;
    display: flex;
    justify-content: center;
    text-align: center;
    justify-content: center;
    margin: 100px 0 ;

}
.contact{
    width: 60%;
}

@media only screen and (max-width: 900px){
    .contact{
        width: 90%;
    }
    
}
