.about-container{
    width: 100%;
    color: #fff;

}
.about-wrapper{
    width: 80%;
    margin: auto;
    height: auto;
}
.about-wrapper-top{
width: 90%;
margin: auto;
height: 200px;

}
.about-h2{
    font-size: 50px;
    line-height: 50px;
}
.about-title{
    display: flex;
    flex-direction: column;
    margin: 50px 0;
    position: relative;
}
.about-title h2{
    position: relative;
    height: 30px;
}
.about-desc{
    margin: 50px 0;
}
.about-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
}
.about-grid-title{
    margin: 20px 0;
    height: 30px;
}

@media only screen and (max-width: 900px){
    .about-wrapper{
        width: 90%;
    }
    .about-grid{
        grid-template-columns: 1fr;
    }
    .about-h2 .type-writer{
        font-size: 40px;
    } 

}

@media only screen and (max-width: 600px){
    .about-h2 .type-writer{
        font-size: 30px;
    } 

}