@keyframes blink {
    from {
        opacity: 0%;
    }

    to {
        opacity: 100%;
    }
}

.type-writer {
    margin: 50px 0;
    width: 90%;
    overflow-wrap: anywhere;
    word-wrap: anywhere;
    word-break: break-word;
    hyphens: auto;
    color: #fff;
    display: inline-block;

}

.type-writer::after {
    content: "|";
    animation: blink 1s infinite;
    color: #fff;
    font-size: auto;
    

}

@media only screen and (max-width: 900px) {
    .type-writer {
        font-size: 15px;
        margin: 20px 0;
    }
}